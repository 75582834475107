import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import {  shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs'; 
import {  paths } from '../../interfaces/swagger-types';
@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends BaseAPI {
  confirmEmail(req: { userId: string; confirmToken: string }): Observable<string> {
    return this.http.post<any>('account/confirm-email', req).pipe(map((item) => item?.jwt));
  }

  loginValidate(req: { userName: string; password: string }) {
    return this.http.post('Authentication/login', req);
  }

  login(req: { userName: string; password: string }): Observable<string> {
    return this.http.post<any>('Authentication/login-2fa', req).pipe(shareReplay(1),  map((item) =>{return item?.jwt}));
  }

  useReportingDB(useReportingDatabase: any): Observable<string>{
    return this.http.get(`AdminAccount/use-reporting-db/${useReportingDatabase}`,{ responseType: 'text' });
  }

  user() {
    type userResponse = paths['/api/User']['get']['responses']['200']['content']['text/json'];
    return this.http.get<userResponse>('user').pipe(shareReplay(1));
  }

  updateUser(user: any, emailConfirmationUrl: any) {
    return this.http.patch('user', user, {
      params: { emailConfirmationUrl: emailConfirmationUrl },
    });
  }

  forgotPassword(req: { email: string; resetPasswordUrl: string, reCaptchaToken: string, app: string }) {
    return this.http.post('account/forgot-password', req);
  }

  resetPassword(req: { email: string; password: string; confirmationToken: string }) {
    return this.http.post('account/reset-password', req);
  }

  changePassword(req: { currentPassword: string; newPassword: string; confirmPassword: string }) {
    return this.http.post('account/change-password', req);
  }

  emailExist(email: string) {
    const body = {
      email: email,
    };
    return this.http.post(`user/user-exists`, body);
  }

  entityUserEmailExist(email: string, entityGuid: string) {
    const body = {
      email: email,
      entityGuid: entityGuid
    };
    return this.http.post(`user/entity-user-exists`, body);
  }
  verifyEmail(body:any){

    return this.http.post('/Account/verify-email',body)
  }
}
