import { Component, OnInit } from '@angular/core';
import { AuthQuery } from 'src/app/routes/authentication/state/auth.query';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  date: number = new Date().getFullYear();
  loggedIn: boolean = false;
  versionNumber!: { number: string; date: string };
  constructor(private authQuery: AuthQuery) {}

  ngOnInit(): void {
    this.versionNumber = {
      number: environment.versionNumber.split('|')[0],
      date: environment.versionNumber.split('|')[1],
    };

    this.authQuery.userLoggedIn$.subscribe((yes) => {
      if (yes) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  }
}
