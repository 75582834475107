import { Directive, HostListener, Inject } from '@angular/core';

import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
  selector: '[appAccordionToggle]',
})
export class AccordionAnchorDirective {
  protected navlink: AccordionLinkDirective;

  constructor(
    @Inject(AccordionLinkDirective) navlink: AccordionLinkDirective,
  ) {
    this.navlink = navlink;
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    if (!this.navlink.sidebarOpen && this.navlink.selected) {
      this.navlink.nav.closeOtherLinks(this.navlink);
    }
    if (this.navlink.selectItem === 'sub') {
      this.navlink.toggle();
    } else if (this.navlink.selectItem === 'link') {
      this.navlink.selected = true;
    }
  }

  public hide() {
    this.navlink.selected = false;
  }
}
