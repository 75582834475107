<ng-container>
  
  <mat-slide-toggle *ngxPermissionsExcept="'reportingDBOnly'" (change)="reportingDBToggleChange($event)" [checked]="useReportingDB" class="uppercase"> Report Mode
  </mat-slide-toggle>

  <div *ngxPermissionsOnly="'reportingDBOnly'"></div><!--For flex box layout when toggle is removed-->

    <div>
      <div class="flex items-center max-h-9">
        <div class="font-semibold">
          <p class="m-0 p-0">{{ userName }}</p>
          <p class="header-role-tag m-0"> Admin </p>
        </div>
        <button
          [matMenuTriggerFor]="profile" 
          class="ml-2 cursor-pointer"
        >
          <div class="h-10 w-10">
            <img
              src="assets/images/profile/individual-icon.svg"
              alt="Profile placeholder profile image"
            />
          </div>
        </button>
      </div>
      <mat-menu #profile="matMenu" class="mymegamenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          Log Out
        </button>
      </mat-menu>
    </div>
  </ng-container>
  