import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { NgxRolesService } from 'ngx-permissions';

export type Permissions =
  | 'users:view'
  | 'users:edit'
  | 'privacyrequest:view'
  | 'privacyrequest:edit'
  | 'orgs:view'
  | 'orgs-info:edit'
  | 'orgs-user-associations:view'
  | 'orgs-user-associations:edit'
  | 'orgs-user-invites:view'
  | 'orgs-user-invites:edit'
  | 'coops:view'
  | 'coops:edit'
  | 'coops-user-invites:view'
  | 'coops-user-invites:edit'
  | 'reports:view'
  | 'reports-learner-metrics:view'
  | 'reports-learner-metrics:edit'
  | 'reports-training-metrics:view'
  | 'purchases:view'
  | 'purchases-subscriptions:view'
  | 'purchases-subscriptions:edit'
  | 'purchases-certs:view'
  | 'purchases-enterprise:view'
  | 'purchases-enterprise:edit'
  | 'courses:view'
  | 'courses:edit'
  | 'reportingDBOnly';

export const adminPermissions: Permissions[] = ['users:view',
'users:edit',
'privacyrequest:view',
'privacyrequest:edit',
'orgs:view',
'orgs-info:edit',
'orgs-user-associations:view',
'orgs-user-associations:edit',
'orgs-user-invites:view',
'orgs-user-invites:edit',
'coops:view',
'coops:edit',
'coops-user-invites:view',
'coops-user-invites:edit',
'reports:view',
'reports-learner-metrics:view',
'reports-learner-metrics:edit',
'reports-training-metrics:view',
'purchases:view',
'purchases-subscriptions:view',
'purchases-subscriptions:edit',
'purchases-certs:view',
'purchases-enterprise:view',
'purchases-enterprise:edit',
'courses:view',
'courses:edit'];

export const supportTechnicianPermissions: Permissions[] = ['users:view', 'users:edit','orgs-user-invites:edit', 'privacyrequest:view',
'privacyrequest:edit','reports-learner-metrics:view',
'reports:view',
'reports-learner-metrics:edit',
'reports-training-metrics:view'];

export const analystPermissions: Permissions[] = ['users:view',
'privacyrequest:view',
'orgs:view',
'orgs-user-associations:view',
'orgs-user-invites:view',
'coops:view',
'coops-user-invites:view',
'reports-learner-metrics:view',
'reports-training-metrics:view',
'purchases-subscriptions:view',
'purchases-certs:view','users:view',
'purchases-enterprise:view',
'privacyrequest:view',
'orgs:view',
'orgs-user-associations:view',
'orgs-user-invites:view',
'coops:view',
'coops-user-invites:view',
'reports:view',
'reports-learner-metrics:view',
'reports-training-metrics:view',
'purchases:view',
'purchases-subscriptions:view',
'purchases-certs:view',
'reportingDBOnly'];

export const leadSupportPermissions: Permissions[] = ['users:view',
'users:edit',
'privacyrequest:view',
'privacyrequest:edit',
'orgs:view',
'orgs-info:edit',
'orgs-user-associations:view',
'orgs-user-associations:edit',
'orgs-user-invites:view',
'orgs-user-invites:edit',
'coops:view',
'coops:edit',
'coops-user-invites:view',
'coops-user-invites:edit',
'reports:view',
'reports-learner-metrics:view',
'reports-learner-metrics:edit',
'reports-training-metrics:view',
'purchases:view',
'purchases-subscriptions:view',
'purchases-subscriptions:edit',
'purchases-enterprise:view',
'purchases-certs:view'];

export const accountingPermissions: Permissions[] = ['purchases:view','purchases-subscriptions:view',
'purchases-subscriptions:edit',
'purchases-certs:view','purchases-enterprise:view'];

export const courseManagerPermissions: Permissions[] = ['reports:view','reports-learner-metrics:view',
'reports-learner-metrics:edit',
'reports-training-metrics:view','courses:view',
'courses:edit']

export type UserRole =  'Admin' | 'SupportTechnician' | 'Analyst' | 'LeadSupport' | 'Accounting' | 'CourseManager';

const permissionsByUserRole: { [Key in UserRole]: Permissions[] } = {
  Admin: adminPermissions,
  SupportTechnician: supportTechnicianPermissions,
  Analyst: analystPermissions,
  LeadSupport: leadSupportPermissions,
  Accounting: accountingPermissions,
  CourseManager: courseManagerPermissions
};

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private ngxRolesService: NgxRolesService) {}
  
  applyRoles(roles: UserRole[]) {
    const isReportingDB = (jwtDecode( window.localStorage.getItem('token') as string) as any).UseReportingDatabase;
    const rolesWithPermissions: any = {};
    roles.map((role) => {
      if(isReportingDB === 'true'){  
        if(permissionsByUserRole[role]){
        rolesWithPermissions[role]=  permissionsByUserRole[role].filter((permission: any) => permission.includes(':view') || permission === 'reportingDBOnly');
        }
      }
      else{
        if(permissionsByUserRole[role]){
            rolesWithPermissions[role] = permissionsByUserRole[role];
        }
      }
    });
   
    this.ngxRolesService.addRolesWithPermissions(rolesWithPermissions);
    
  }



  flushRoles() {
    this.ngxRolesService.flushRolesAndPermissions();
  }
}
