import { Injectable } from '@angular/core';
import { ApiModule } from '../api.module';
import { AuthApiService } from './auth/auth-api.service';
import { LookupsService } from './lookups/lookups.service';
import { AllService } from './organizations/all/all.service';
import { UserAssociationsService } from './organizations/user-associations/user-associations.service';
import { UserInvitesService } from './organizations/user-invites/user-invites.service';
import { LearnerMetricsService } from './reports/learner-metrics/learner-metrics.service';
import { UsersService } from './users/users.service';
import { CourseApiService } from './courses/course-api.service';
import { CoursesService } from './courses/courses.service';
import { CertificatesService } from './reports/certificates/certificates.service';
import { CooperativeAllService } from './cooperations/coop-all/cooperative-all.service';
import { DashboardService } from './reports/dashboard/dashboard.service';

@Injectable({ providedIn: ApiModule })
export class ApiService {
  constructor(
    public auth: AuthApiService,
    public orgAll: AllService,
    public lookups: LookupsService,
    public orgAssociations: UserAssociationsService,
    public orgUserInvites: UserInvitesService,
    public usersService: UsersService,
    public learnerMetricsService: LearnerMetricsService,
    public courseApiService: CourseApiService,
    public coursesService: CoursesService,
    public certificatesService: CertificatesService,
    public coopAll: CooperativeAllService,
    public dashboardService: DashboardService
  ) {}
}
