<mat-tab-group>
  <mat-tab label="Daily Reports">
    <ng-template mat-tab-label>
      Daily Reports
    </ng-template>
    <div class="flex flex-col w-full" [formGroup]="filtersForm">
      <div class="px-4">
        <div class="flex flex-row justify-end items-center">
          <div class="flex flex-row flex-wrap">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 mr-4">
                <mat-label>From</mat-label>
                <input matInput formControlName="fromDateDaily" [matDatepicker]="picker1" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 mr-4">
                <mat-label>To</mat-label>
                <input matInput formControlName="toDateDaily" [matDatepicker]="picker2" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayCharts" class="w-full self-center items-center justify-center bg-color-lightBlue px-4">
      <div class="w-full flex text-center gap-5 pt-4">
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Certificates</h2>
            <h2 class="learner">{{ formatToDollarAmount(dailyTotalCertificates) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Pay-As-You-Go</h2>
            <h2 class="learner">{{ formatToDollarAmount(dailyTotalPayAsYouGo) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Subscriptions</h2>
            <h2 class="learner">{{ formatToDollarAmount(dailyTotalSubscriptions) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Renewals</h2>
            <h2 class="learner">{{ formatToDollarAmount(dailyTotalRenewal) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Total</h2>
            <h2 class="learner">{{ formatToDollarAmount(dailyTotalPurchases) }}</h2>
          </div>
        </div>
      </div>
    
    
    
      <div class="paid-certs-chart w-full justify-center  pt-4">
          <div class="dashboard-card">
            <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
              <h2 class="learner">Paid Certifications by Course</h2>
            </div>
            <apx-chart
              id="paidCertsChart"
              [series]="paidCertsOptions.series"
              [chart]="paidCertsOptions.chart"
              [labels]="paidCertsOptions.labels"
              [responsive]="paidCertsOptions.responsive"
              [colors]="paidCertsOptions.colors"
              [legend]="paidCertsOptions.legend"
              [stroke]="paidCertsOptions.stroke"
              [tooltip]="paidCertsOptions.tooltip"
            ></apx-chart>
            <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
              <h2 class="learner">Total Paid Certificates: {{totalCertificates  | number:'1.0-0' }}</h2>
              <p>
                <a routerLink="/purchases/certificates" class="primary-link underline">View Certificates</a>
              </p>
            </div>
          </div>
    
      </div>
      <div class="organization-subscription w-full justify-center pt-4">
        <div class="dashboard-card">
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Subscriptions Revenue</h2>
          </div>
          <apx-chart
            [series]="orgSubscriptionsRevenue.series"
            [chart]="orgSubscriptionsRevenue.chart"
            [dataLabels]="orgSubscriptionsRevenue.dataLabels"
            [plotOptions]="orgSubscriptionsRevenue.plotOptions"
            [xaxis]="orgSubscriptionsRevenue.xaxis"
            [yaxis]="orgSubscriptionsRevenue.yaxis"
            [colors]="orgSubscriptionsRevenue.colors"
            [tooltip]="orgSubscriptionsRevenue.tooltip"
          ></apx-chart>
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Total: {{formatToDollarAmount(dailyTotalSubscriptions)}}</h2>
            <p>
              <a routerLink="/purchases/subscription-report" class="primary-link underline">View Subscriptions</a>
            </p>
            
          </div>
        </div>
      </div>
      <div class="course-registration-cert w-full justify-center pt-4">
        <div class="dashboard-card">
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Training Conversions - Courses (Paid)</h2>
          </div>
          <apx-chart
          id="trainingConversionsChart"
          [series]="courseRegCertOptions.series"
          [chart]="courseRegCertOptions.chart"
          [dataLabels]="courseRegCertOptions.dataLabels"
          [plotOptions]="courseRegCertOptions.plotOptions"
          [legend]="courseRegCertOptions.legend"
          [fill]="courseRegCertOptions.fill"
          [stroke]="courseRegCertOptions.stroke"
          [tooltip]="courseRegCertOptions.tooltip"
          [xaxis]="courseRegCertOptions.xaxis"
          [yaxis]="courseRegCertOptions.yaxis"
          [colors]="courseRegCertOptions.colors"
        ></apx-chart>
        <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
          <h2 class="learner">Registrations: {{ totalPaidRegistration  | number:'1.0-0'}}</h2>
          <h2 class="learner">Certificates: {{totalPaidCertificates  | number:'1.0-0' }}</h2>
          <p>
            <a routerLink="/purchases/certificates" class="primary-link underline">View Certificates</a>
          </p>
          
        </div>
        </div>
      </div>
    
      <div class="course-registration-cert-free w-full justify-center py-4">
        <div class="dashboard-card">
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Training Conversions - Courses (Free)</h2>
          </div>
          <apx-chart
          id="trainingConversionsChart"
          [series]="courseFreeCertOptions.series"
          [chart]="courseFreeCertOptions.chart"
          [dataLabels]="courseFreeCertOptions.dataLabels"
          [plotOptions]="courseFreeCertOptions.plotOptions"
          [legend]="courseFreeCertOptions.legend"
          [fill]="courseFreeCertOptions.fill"
          [stroke]="courseFreeCertOptions.stroke"
          [tooltip]="courseFreeCertOptions.tooltip"
          [xaxis]="courseFreeCertOptions.xaxis"
          [yaxis]="courseFreeCertOptions.yaxis"
          [colors]="courseFreeCertOptions.colors"
        ></apx-chart>
        <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
          <h2 class="learner">Registrations: {{ totalFreeRegistration  | number:'1.0-0'}}</h2>
          <h2 class="learner">Certificates: {{ totalFreeCertificates  | number:'1.0-0' }}</h2>
          <p>
            <a routerLink="/purchases/certificates" class="primary-link underline">View Certificates</a>
          </p>
          
        </div>
        </div>
      </div>
    

    </div>
  </mat-tab>
  <mat-tab label="Trend Reports">
    <ng-template mat-tab-label>
      Trend Reports
    </ng-template>
    <div class="flex flex-col w-full" [formGroup]="filtersForm">
      <div class="px-4">
        <div class="flex flex-row justify-end items-center">
          <div class="flex flex-row flex-wrap">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 mr-4">
                <mat-label>From</mat-label>
                <input matInput formControlName="fromDateTrend" [matDatepicker]="picker3" />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 mr-4">
                <mat-label>To</mat-label>
                <input matInput formControlName="toDateTrend" [matDatepicker]="picker4" />
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayCharts" class="w-full self-center items-center justify-center bg-color-lightBlue px-4">
      <div class="w-full flex text-center gap-5 pt-4">
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Certificates</h2>
            <h2 class="learner">{{ formatToDollarAmount(trendTotalCertificates) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Pay-As-You-Go</h2>
            <h2 class="learner">{{ formatToDollarAmount(trendTotalPayAsYouGo) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Subscriptions</h2>
            <h2 class="learner">{{ formatToDollarAmount(trendTotalSubscriptions) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Renewals</h2>
            <h2 class="learner">{{ formatToDollarAmount(trendTotalRenewal) }}</h2>
          </div>
        </div>
        <div class="w-1/5">
          <div class="dashboard-card">
            <h2 class="learner">Total</h2>
            <h2 class="learner">{{ formatToDollarAmount(trendTotalPurchases) }}</h2>
          </div>
        </div>
      </div>
      <div class="organization-subscription w-full justify-center pt-4">
        <div class="dashboard-card">
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Organization Revenue</h2>
          </div>
          <apx-chart
            [series]="OrganizationRevenueMonthly.series"
            [chart]="OrganizationRevenueMonthly.chart"
            [dataLabels]="OrganizationRevenueMonthly.dataLabels"
            [plotOptions]="OrganizationRevenueMonthly.plotOptions"
            [xaxis]="OrganizationRevenueMonthly.xaxis"
            [yaxis]="OrganizationRevenueMonthly.yaxis"
            [colors]="OrganizationRevenueMonthly.colors"
            [tooltip]="OrganizationRevenueMonthly.tooltip"
          ></apx-chart>
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Total: {{formatToDollarAmount(trendTotalPayAsYouGo + trendTotalSubscriptions + trendTotalRenewal )}}</h2>
            <!-- <p>
              <a routerLink="/purchases/subscription-report" class="primary-link underline">View Subscriptions</a>
            </p> -->
            
          </div>
        </div>
      </div>
    
      <div class="organization-subscription w-full justify-center pt-4">
        <div class="dashboard-card">
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Total Revenue</h2>
          </div>
          <apx-chart
            [series]="TotalRevenueMonthly.series"
            [chart]="TotalRevenueMonthly.chart"
            [dataLabels]="TotalRevenueMonthly.dataLabels"
            [plotOptions]="TotalRevenueMonthly.plotOptions"
            [xaxis]="TotalRevenueMonthly.xaxis"
            [yaxis]="TotalRevenueMonthly.yaxis"
            [colors]="TotalRevenueMonthly.colors"
            [tooltip]="TotalRevenueMonthly.tooltip"
          ></apx-chart>
          <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
            <h2 class="learner">Total: {{formatToDollarAmount(trendTotalPurchases)}}</h2>
            <!-- <p>
              <a routerLink="/purchases/subscription-report" class="primary-link underline">View Subscriptions</a>
            </p> -->
            
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

