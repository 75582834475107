<div class="snackbar-card">
    <div class="message-div"
    [ngStyle]="{ 
        'border-left-color': getBorderColor(data.toastType)
    }"
    >
        <div class="snackbar-exit-btn">
            <button (click)="closeSnackbar()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="pb-2">
            <h2 class="learner">
                {{ data.toastType }}
            </h2>

            <p class="message">
                {{ data.message }}
            </p>
        </div>
    </div>
</div>
