import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MediaMatcher, BreakpointObserver } from '@angular/cdk/layout';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from 'src/app/api/services/api.service';
import { tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  mobileQuery!: MediaQueryList;
  dir = 'ltr';
  green = false;
  blue = false;
  dark = false;
  minisidebar = false;
  boxed = false;
  danger = false;
  showHide = false;
  url = '';
  sidebarOpened = false;
  status = false;
  loadTour: boolean = false;
  miniHeader:boolean = false;
  public showSearch = false;
  date: number = new Date().getFullYear();
  versionNumber!: { number: string; date: string };
  useReportingDB: boolean = false;


  @ViewChild(MatSidenav, { static: false }) sideNav!: MatSidenav;

  private readonly _mobileQueryListener: () => void;
  clickEvent() {
    this.status = !this.status;
    this.miniHeader = !this.miniHeader;
    this.sideNav.toggle()
  }
  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private api: ApiService,
  ){
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    window.onbeforeunload = function () {
      if (localStorage['theme'] === 'hc') {
        localStorage.removeItem('theme');
      }
    };
  }
  isElement = 'scrolldiv';
  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.breakpointObserver.observe('(min-width: 768px)').subscribe((matches) => {
      this.sidebarOpened = matches.matches;
    });

    this.versionNumber = {
      number: environment.versionNumber.split('|')[0],
      date: environment.versionNumber.split('|')[1],
    };
  }


// Method to handle the event emitted by the header component
  handleReportBanner(value: boolean) {
    this.useReportingDB = value;
  }


  

}
