import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { filter, map, Observable, take } from 'rxjs';
import { AuthService } from '../state/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard  {
  constructor(private authServ: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userLoggedIn().pipe(take(1));
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userLoggedIn().pipe(take(1));;
  }
  private userLoggedIn() {
    this.authServ.loadUserDetails();
    return this.authServ.userLoggedIn$.pipe(
      filter((userIsLoggedIn) => userIsLoggedIn !== null),
      map((userIsLoggedIn) => userIsLoggedIn as boolean),
      map((user) => {
        if (user) {
          return true;
        } else {
          this.router.navigateByUrl('/authentication/login');
          return false;
        }
      })
    );
  }
}
