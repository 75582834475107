import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';
import { Observable } from 'rxjs';
import { share, take } from 'rxjs/operators';

export type CoursesResponse =
  paths['/api/LearnerCourse/{entityGuid}']['get']['responses']['200']['content']['text/json'];

@Injectable({
  providedIn: 'root',
})
export class CourseApiService extends BaseAPI {

  public courses(entityGuid: any): Observable<CoursesResponse | null> {
    return this.http.get<CoursesResponse>(`AdminCourseVersion/entityCourses/${entityGuid}`).pipe(take(1));
  }

  public addOrgCourses(coursesToAdd: number[], entityGuid: any) {
    return this.http.post(`AdminCourseVersion/add-entity-courses/${entityGuid}`, coursesToAdd);
  }

  toggleActiveAndInactive(courseId: any, entityGuid: any) {
    return this.http.put(`AdminCourseVersion/toggle-entity-course-status/${entityGuid}/${courseId}`, null);
  }
}
