import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DashboardChartsStore, DashboardChartsState } from './dashboard.store';

@Injectable({ providedIn: 'root' })
export class DashboardChartsQuery extends QueryEntity<DashboardChartsState> {

  constructor(protected store: DashboardChartsStore) {
    super(store);
  }

}
