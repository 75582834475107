import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';

export type CoursesResponse =
  paths['/api/LearnerCourse/{entityGuid}']['get']['responses']['200']['content']['text/json'];

@Injectable({
  providedIn: 'root',
})
export class CoursesService extends BaseAPI {
  getModulesGrid() {
    return this.http.get(`AdminCourseVersion/modules`);
  }

  getCourseVersionGrid() {
    return this.http.get(`AdminCourseVersion/courseversions`);
  }

  getPaidProductsGrid() {
    return this.http.get(`AdminCourseVersion/paid-products`);
  }

  getCourseVersionDetails(courseVersionId: any) {
    return this.http.get(`AdminCourseVersion/${courseVersionId}`);
  }

  getModuleDetails(moduleId: any) {
    return this.http.get(`AdminCourseVersion/module/${moduleId}`);
  }

  postCreateNewCourse(model: any) {
    return this.http.post(`AdminCourse`, model);
  }

  postAddCourseVersion(model: any) {
    return this.http.post(`AdminCourseVersion/add-course-version`, model);
  }

  postNewModule(model: any) {
    return this.http.post(`AdminCourseVersion/module`, model);
  }

  postAddPaidProduct(model: any) {
    return this.http.post(`AdminCourseVersion/add-paid-product`, model);
  }

  postUploadFile(model: any) {
    return this.http.post(`AdminCourse/upload-file`, model, { responseType: 'text' });
  }
}
