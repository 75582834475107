<mat-nav-list appAccordion class="pt-5">
  <mat-list-item
    appAccordionLink
    sidebarOpen="true"
    *ngFor="let menuitem of mi$ | async; let i = index"
    group="{{ menuitem.state }}"
    (click)="scrollToTop()"
    class="hover:bg-transparent mb-2 tour-{{ menuitem?.name }} "
  >
    <a
      class="flex items-center py-md px-md w-full font-medium text-tertiary"
      appAccordionToggle
      [routerLink]="menuitem.state"
      routerLinkActive="selected"
      *ngIf="menuitem.type === 'link'"
    >
      <mat-icon class="mr-sm">{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name }}</span>
      <span></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a
      class="flex items-center custom-dropdown py-md px-md w-full font-semibold text-tertiary"
      *ngIf="menuitem.type === 'sub'"
      (click)="openSidebar.next()"
      appAccordionToggle
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name }}</span>
      <span class="flex-1"></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>
    <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
      <mat-list-item *ngFor="let childitem of menuitem.children; let j = index">
        <a
          [routerLink]="['/', menuitem.state, childitem.state]"
          *ngIf="childitem.type === 'link'"
          class="relative"
          routerLinkActive="selected"
          (click)="itemSelect[childitem] = j"
          >{{ childitem.name }}</a
        >
      </mat-list-item>
    </mat-nav-list>
    <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
      <span>{{ menuitem.name }}</span>
    </div>
  </mat-list-item>
  <mat-list-item routerLinkActive="selected" (click)="logout()" class="hover:bg-transparent mb-2">
    <a class="flex items-center py-md px-md w-full font-medium text-tertiary">
      <mat-icon class="mr-sm">logout</mat-icon>
      <span>Log Out</span>
      <span></span>
      <span class="label"></span>
    </a>
  </mat-list-item>
</mat-nav-list>
