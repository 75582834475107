import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';

@Injectable({
  providedIn: 'root',
})
export class UserAssociationsService extends BaseAPI {
  getAll(paramsRequested: any) {
    return this.http.get('adminEntity/userassociations-grid', {
      params: paramsRequested,
    });
  }

  assign(formValues: any) {
    return this.http.post(`adminEntityUser/entity-user-roles`, formValues);
  }
  assignStatus(formValues: any) {
    return this.http.post(`adminEntityUser/update-entity-user-status`, formValues);
  }

  export(params: any) {
    return this.http.get(`adminEntity/userassociations-grid/export`, {
      params: params,
    });
  }

  // User Association for Cooperative
  getAllCoops(paramsRequested: any) {
    return this.http.get('adminEntity/cooperative-userassociations-grid', {
      params: paramsRequested,
    });
  }

  exportCoops(params: any) {
    return this.http.get(`adminEntity/cooperative-userassociations-grid/export`, {
      params: params,
    });
  }

  addUserToCoop(formValues: any) {
    return this.http.post(`adminEntity/add-user-to-coop`, formValues);
  }
}
