import { Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { AuthStore } from './auth.store';
import { AuthApiService } from '../../../api/services/auth/auth-api.service';
import { AuthQuery } from './auth.query';
import { Router } from '@angular/router';
import {
  catchError,
  distinctUntilChanged,
  map, 
  switchMap, 
  tap,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { components, paths } from '../../../api/interfaces/swagger-types';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { jwtDecode } from 'jwt-decode';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
 

export interface UserModel extends Partial<components['schemas']['UserProfileViewModel']> {}
export interface Auth {
  jwt: string | null;
  user?: UserModel | undefined | null | boolean;
  useReportingDB: null |boolean;
  userIsLoggedIn: null | boolean;
  profilePercentComplete: number;
}

const initialValue: Auth = {
  jwt: null,
  useReportingDB: null,
  userIsLoggedIn: null,
  profilePercentComplete: 0,
};

@Injectable({ providedIn: 'root' })
export class AuthService {

  private store = new BehaviorSubject<Auth>(initialValue);
  public jwt$ = this.store.pipe(map(x => x?.jwt), distinctUntilChanged());
  public user$ = this.store.pipe(map(x => x?.user), distinctUntilChanged());
  public isUsingReportingDB$ = this.store.pipe(map(x => x?.useReportingDB), distinctUntilChanged());
  public userLoggedIn$ = this.store.pipe(map(x => x?.userIsLoggedIn), distinctUntilChanged());

  constructor(private authStore: AuthStore,  private authQuery: AuthQuery, private router: Router, private authApiService:AuthApiService, private snackBar: MatSnackBar ) {
    // When the app loads grab a JWT from localStorage, if it exists.
    const jwt = window.localStorage.getItem('token');
    if(jwt){
      const decodedJWT: any = jwtDecode(jwt);
      if(decodedJWT.UseReportingDatabase =='true'){
        this.storeJWT(jwt as string, true);
      }
      else{
        this.storeJWT(jwt as string, false);
      
      }
    }
    this.store.next({ ...this.store.value, jwt });
    // Whenever the JWT token changes get the user's details
    this.jwt$.subscribe(() => {
      // this.loadUserDetails();
    });
  }

  public useReportingDB(useReportingDatabase: boolean): Observable<boolean> {
    this.authStore.setLoading(true);
    return this.authApiService.useReportingDB(useReportingDatabase).pipe(
      catchError((e) => throwError(() => e)),
      
      map((jwt) => {
        
        this.authStore.setLoading(false);
        const success = Boolean(jwt);
        const token = jwt as string;       

        if (success) {
          this.authStore.update((state) => ({ ...state, jwt: token}));
          this.storeJWT(jwt as string, useReportingDatabase);
          this.router.navigateByUrl('/dashboard').then(() =>{
            window.location.reload();
          });
        }

        return success;
      })
    );
  }

  public loginValidate(req: { userName: string; password: string }) {
    return this.authApiService.loginValidate(req);
  }

  public login(req: { userName: string; password: string }): Observable<boolean> {
    this.authStore.setLoading(true);
    return this.authApiService.login(req).pipe(
      catchError((e) => throwError(() => e)),
      
      map((jwt) => {
        
        this.authStore.setLoading(false);
        const success = Boolean(jwt);
        const token = jwt as string;
        

        if (success) {
          // const userIsLoggedIn = true;
          this.authStore.update((state) => ({ ...state, jwt: token}));
          const isReportingDB = (jwtDecode( jwt as string) as any).UseReportingDatabase;
          this.storeJWT(jwt as string, isReportingDB);
          resetStores();
          // this.loadUserDetails();
        }

        return success;
      })
    );
  }

  public storeJWT(token: string, useReportingDB: boolean, userIsLoggedIn?: boolean) {
    window.localStorage.setItem('token', token);
    this.store.next({
      ...this.store.value,
      jwt: token,
      useReportingDB: useReportingDB,
      userIsLoggedIn: userIsLoggedIn ? userIsLoggedIn : null,
    });
    
  }
  public loadUserDetails() {
    
    if (!this.authQuery.getValue().jwt && !this.store.value.jwt) {
      this.store.next({
        ...this.store.value,
        user: null,
        userIsLoggedIn: false,
      });

      return;
    }

    this.authStore.setLoading(true);
    this.authApiService
      .user()
      .pipe(
        catchError((error) => {
          this.authStore.setLoading(false);
          return of(false);
        })
      )
      .subscribe({
        next: (user:any) => {
          this.authStore.setLoading(false);
          if (!user) {
            this.store.next({ ...this.store.value, user, userIsLoggedIn: false });
  
            this.logout();
            return;
          }
          if(!(user?.roles?.includes('Admin') || user?.roles?.includes('LeadSupport') || user?.roles?.includes('SupportTechnician') || user?.roles?.includes('CourseManager')
            || user?.roles?.includes('Accounting') || user?.roles?.includes('Analyst'))){
            this.logout();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Not Authorized' }})
            return
          }
    
          this.store.next({ ...this.store.value, user, userIsLoggedIn: true });
          this.authStore.update((state) => ({
            ...state,
            user,
            userIsLoggedIn: true,
          }));
        }
      });
  }
  public logout() {
   
    this.store.next({ ...initialValue });
    resetStores();
    window.localStorage.removeItem('token');
    this.router.navigateByUrl('/authentication/login').then(() =>{
      window.location.reload();
    });
  }



}
