import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DashboardCharts } from './dashboard.model';

export interface DashboardChartsState extends EntityState<DashboardCharts> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dashboard' })
export class DashboardChartsStore extends EntityStore<DashboardChartsState> {

  constructor() {
    super();
  }

}
