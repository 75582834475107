import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder,FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, throwError } from 'rxjs';
import { ApiService } from 'src/app/api/services/api.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
const newPassword = new FormControl(null, Validators.required);
// @ts-ignore
const confirmPassword = new FormControl(null, Validators.required);

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public form: FormGroup = Object.create(null);
  disableFlag:boolean = false;
   hide = true;
   chide = true;
   constructor(
     private fb: FormBuilder,
     private router: Router,
     private _api: ApiService,
     private route: ActivatedRoute,
     private snackBar: MatSnackBar
   ) {}
 
   token: any = '';
 
   ngOnInit(): void {
     this.form = this.fb.group({
       email: this.fb.control(null, Validators.compose([Validators.required, Validators.email])),
       newPassword : this.fb.control(null, Validators.required),
       confirmPassword: this.fb.control(null, Validators.required),
     }, 
     {validators: [this.mustMatch('newPassword', 'confirmPassword')]} as AbstractControlOptions );
 
     const { confirmationToken, email } = this.route.snapshot.queryParams;
     const replaceEmail = email.replace(' ', '+');
     this.form.controls['email'].setValue(replaceEmail);
     this.form.controls['email'].disable();
     this.token = confirmationToken;
   }
 
   onSubmit() {
     if (!this.form.valid) {
       this.form.markAllAsTouched();
       return;
     }
     this.disableFlag = true;
     const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
       if (validationErrors.length) {
         validationErrors.forEach((error: any) => {
           const control = this.form.get(error.field);
           if (control) {
             this.form.markAllAsTouched();
             control.setErrors({
               message: error.message,
             });
           } else if (error?.field === 'password') {
             const control = this.form.get('newPassword');
             if (control) {
               this.form.markAllAsTouched();
               control.setErrors({
                 message:
                   'The Password does not meet requirements: minimum of 6 characters and contain at least one of each of the following: uppercase letter, lowercase letter, special character, and number.',
               });
             }
           } else {
             this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
           }
         });
       } else {
         this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
       }
       return of(null);
     };
     if (this.form.valid) {
       const email = this.form.get('email')?.value;
       const password = this.form.get('newPassword')?.value;
       const confirmationToken = this.token;

       let formValues = {
          email: email,
          password: password,
          confirmationToken: confirmationToken
       }


       this._api.auth
       .resetPassword(formValues)
       .pipe(catchError((error) => throwError(error)))
       .subscribe(
         () => {
           this.snackBar.openFromComponent(SnackbarComponent, {
             duration: 3000,
             data: { toastType: ToastType.Success, message: 'Password Reset Successfully!' },
           });
           this.router.navigate(['/login']);
           this.disableFlag = false;
         },
         (error) => {
           this.disableFlag = false;
           console.log(error);
           validationErrorHandler(error);
         },
       );




      //  this._api.auth
      //    .resetPassword({ email, password, confirmationToken })
      //    .pipe(catchError((error) => throwError(() => error)))
      //    .subscribe(
      //      {
      //       next: () => {
      //        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Password Reset Successfully!' }})
      //        this.router.navigate(['/authentication/login']);
      //        this.disableFlag = false;
      //      },
      //      error: (error) => {
      //        this.disableFlag = false;
      //        console.log(error);
      //        validationErrorHandler(error);
      //      }}
      //    );
     }
   }

   mustMatch(control1:any, control2:any){
      return (fg:FormGroup) => {
        const ctrl1 = fg.controls[control1];
        const ctrl2 = fg.controls[control2];
        if(ctrl2.errors && !ctrl2.errors['message']){
          return
        }

        if(ctrl1?.value !== ctrl2?.value){
          ctrl2.setErrors({mustMatch: true})
        } else {
          ctrl2.setErrors(null)

        }
      }
   }

}
