import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';

@Injectable({
  providedIn: 'root',
})
export class UserInvitesService extends BaseAPI {
  getAll(paramsRequested: any) {
    return this.http.get('adminEntityUserInvite/userinvites-grid', {
      params: paramsRequested,
    });
  }
  resendInvitation(formValues: any) {
    return this.http.post(`adminEntityUserInvite/resend`, formValues);
  }

  cancelInvite(inviteGuid: any) {
    return this.http.post(`adminEntityUserInvite/cancel/${inviteGuid}`, null);
  }
  sendInvite(formValues: any) {
    return this.http.post(`adminEntityUserInvite/invite-to-org`, formValues);
  }
  export(params: any) {
    return this.http.get(`adminEntityUserInvite/userinvites-grid/export`, { params: params });
  }
}
