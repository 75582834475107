import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {
  fontSize = 16;
  currentUrl: boolean = true;
  loadLoginHtml: boolean = true;
  private initialCSSVars: any;
  date: number = new Date().getFullYear();
  
  constructor(public router: Router) {
    this.router.events.subscribe((res) => {
      if (
        this.router.url === '/authentication/login' ||
        this.router.url === '/authentication/email-confirmed' ||
        this.router.url === '/authentication/forgot'
      ) {
        this.currentUrl = false;
      } else {
        this.currentUrl = true;
      }
    });
    window.onbeforeunload = function () {
      if (localStorage['theme'] === 'hc') {
        localStorage.removeItem('theme');
      }
    };
    this.initialCSSVars = getCSSCustomPropIndex().reduce((acc: any, cur: any) => {
      acc[`${cur[0]}`] = cur[1];
      return acc;
    }, {});
   }

   ngOnInit() {}

   ngOnDestroy() {}
 
   resetFontSite() {
     this.setRootSize(16);
   }
 
   decreaseFontSize() {
     this.setRootSize(this.getRootSize() - 2);
   }
 
   increaseFontSize() {
     this.setRootSize(this.getRootSize() + 2);
   }
 
   setRootSize(size: number) {
     this.fontSize = size;
     return (document.documentElement.style.fontSize = size + 'px');
   }
 
   getRootSize(): number {
     const fontSize = document.documentElement.style?.fontSize?.replace(/\D/g, '') || 16;
     // @ts-ignore
     return parseInt(fontSize, 10);
   }
 
   resetContrast() {
     localStorage.removeItem('theme');
     if (this.initialCSSVars) {
       this.updateCssVars(this.initialCSSVars);
     }
   }
 
   highContrast() {
     localStorage.setItem('theme', 'hc');
 
     const highContrastTheme = {
       '--primary-text': 'var(--primary-text-hc)',
       '--primary': 'var(--primary-text-hc)',
       '--secondary-btn': 'var(--secondary-btn-hc)',
       '--gray-400': 'var(--gray-400-hc)',
     };
 
     this.updateCssVars(highContrastTheme);
   }
 
   updateCssVars(theme: { [key: string]: string }) {
     Object.entries(theme).map(([key, value]) => {
       this.updateCssVar(key, value);
     });
   }
 
   updateCssVar(prop: string, val: string) {
     let root = document.documentElement;
     root.style.setProperty(prop, val);
   }

}

// @ts-ignore
const isSameDomain = (styleSheet) => {
  if (!styleSheet.href) {
    return true;
  }

  return styleSheet.href.indexOf(window.location.origin) === 0;
};

// @ts-ignore
const isStyleRule = (rule) => rule.type === 1;

const getCSSCustomPropIndex = () =>
  // @ts-ignore
  [...document.styleSheets].filter(isSameDomain).reduce(
    (finalArr, sheet) =>
      finalArr.concat(
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map((propName) => [propName.trim(), rule.style.getPropertyValue(propName).trim()])
            .filter(([propName]) => propName.indexOf('--') === 0);

          return [...propValArr, ...props];
        }, [])
      ),
    []
  );