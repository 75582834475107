import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './shared/components/error/error.component';
import { BlankComponent } from './shared/components/layouts/blank/blank.component';
import { FullComponent } from './shared/components/layouts/full/full.component';
import { AuthGuardGuard } from './routes/authentication/guard/auth-guard.guard';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { ResetPasswordComponent } from './routes/authentication/components/reset-password/reset-password.component';

const routes: Routes = [
  {path: 'authentication', redirectTo: '/authentication/login', pathMatch: 'full'},
  {path: 'reset', component: ResetPasswordComponent} ,
  {
    path: 'authentication',
    component: BlankComponent,
    loadChildren: () => import('./routes/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuardGuard],
    children: [
      {
        path: '404',
        component: ErrorComponent,
      },
      {
        path: '401',
        component: UnauthorizedComponent,
      },
      { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
      {
        path: 'dashboard',
        data: {
          title: 'Dashboard',
          urls: [{ title: 'Dashboard' }],
        },
        component:DashboardComponent,
        loadChildren: () => import('./routes/dashboard/dashboard.module').then((m) => m.DashboardModule)

      },
      {
        path: 'organizations',
        canActivate: [ngxPermissionsGuard],
       data: {
          permissions: {
            only: ['orgs:view'],
            redirectTo: '/401',
          },
        },
        loadChildren: () => import('./routes/organization/organization.module').then((m) => m.OrganizationModule),
      },
      {
        path: 'cooperatives',
        data: {
          permissions: {
            only: ['coops:view'],
            redirectTo: '/401',
          },
        },
        loadChildren: () => import('./routes/cooperative/cooperative.module').then((m) => m.CooperativeModule),
      },
      {
        path: 'users',
        data: {
          permissions: {
            only: ['users:view'],
            redirectTo: '/401',
          },
        },
        loadChildren: () => import('./routes/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'reports',
        data: {
          permissions: {
            only: ['reports:view'],
            redirectTo: '/401',
          },
        },
        loadChildren: () => import('./routes/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'courses',
        data: {
          permissions: {
            only: ['courses:view'],
            redirectTo: '/401',
          },
        },
        loadChildren: () => import('./routes/courses/courses.module').then((m) => m.CoursesModule),
      },
      {
        path: 'revenue',
        data: {
          permissions: {
            only: ['purchases:view'],
            redirectTo: '/401',
          },
        },
        loadChildren: () => import('./routes/purchases/purchases.module').then((m) => m.PurchasesModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
