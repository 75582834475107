import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';
import { paths } from '../../../interfaces/swagger-types';
import { tap } from 'rxjs';
export type Parameters = paths['/api/AdminEntity/organization-grid']['get']['parameters'];

@Injectable({
  providedIn: 'root',
})
export class CooperativeAllService extends BaseAPI {
  getAll(paramsRequested: any) {
    return this.http.get('adminEntity/cooperative-grid', {
      params: paramsRequested,
    });
  }

  addCooperative(body: any) {
    return this.http.post(`adminEntity/coop`, body);
  }
}
