<div class="items-center flex pt-10">
    <div class="w-full h-full flex justify-center">
      <div class="rounded-lg p-5 sm:w-4/5 box-shadow-custom bg-white">
        <div class="p-5">
          <div class="">
            <div class="text-center">
              <h1 class="m-t-0 text-primary font-bold mb-3">
                Sorry, we can't seem to find that page.
              </h1>
              <h4 class="m-t-0 text-primary font-bold mb-3">404 Error</h4>
            </div>
            <p class="text-primary text-center text-base mb-4">
              You've landed on a page that doesn't exist. If you believe you've reached this page in error, please contact
              us. Otherwise it's recommended to start again from the home page.
            </p>
  
            <div class="flex justify-center items-center text-center">
              <button type="submit" (click)="goToHome()" class="btn-primary large m-t-20">
                Return to Home Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  