import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseAPI {

  getAll(paramsRequested: any) {
    return this.http.get('adminReports/dashboard', {
      params: paramsRequested,
    });
  }

}
