import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { DashboardCharts } from './dashboard.model';
import { DashboardChartsStore } from './dashboard.store';

@Injectable({ providedIn: 'root' })
export class DashboardChartsService {
  constructor(private DashboardChartsStore: DashboardChartsStore, private api: ApiService) {}
  
  all(params: any) {
    let rows = this.api.dashboardService.getAll(params).pipe(
      tap((entities: DashboardCharts) => {
        const { dailyTotalPurchases, dailyTotalCertificates, dailyTotalPayAsYouGo, dailyTotalSubscriptions, dailyTotalRenewal, 
          trendTotalPurchases, trendTotalCertificates, trendTotalPayAsYouGo, trendTotalSubscriptions, trendTotalRenewal, adminDashboardCharts } = entities;
        const newRows = adminDashboardCharts?.map((row: any) => {
          return {
            ...row,
          };
        });
        const newEntities = {
          dailyTotalPurchases: dailyTotalPurchases,
          dailyTotalCertificates: dailyTotalCertificates,
          dailyTotalPayAsYouGo: dailyTotalPayAsYouGo,
          dailyTotalSubscriptions: dailyTotalSubscriptions,
          dailyTotalRenewal: dailyTotalRenewal,
          trendTotalPurchases: trendTotalPurchases,
          trendTotalCertificates: trendTotalCertificates,
          trendTotalPayAsYouGo: trendTotalPayAsYouGo,
          trendTotalSubscriptions: trendTotalSubscriptions,
          trendTotalRenewal: trendTotalRenewal,
          adminDashboardCharts: newRows,
        };
        this.DashboardChartsStore.set([{ ...newEntities }]);
      })
    );
    return rows;
  }

}
