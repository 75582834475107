import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';
import { paths } from '../../../interfaces/swagger-types';
import { tap } from 'rxjs';
export type Parameters = paths['/api/AdminEntity/organization-grid']['get']['parameters'];

@Injectable({
  providedIn: 'root',
})
export class AllService extends BaseAPI {
  getAll(paramsRequested: any) {
    return this.http.get('adminEntity/organization-grid', {
      params: paramsRequested,
    });
  }

  getSettings(id: any) {
    return this.http.get(`adminEntity/${id}`);
  }

  getAppointedCertificates(entityId: any) {
    return this.http.get(`adminEntity/appointed-cert/${entityId}`);
  }

  addAppointedCertificates(body: any) {
    return this.http.post(`adminEntity/appointed-cert`, body);
  }

  getEntityEnterprise(entityId: any) {
    return this.http.get(`adminEntity/enterprise-subscription/${entityId}`);
  }

  updateEnterprise(body: any) {
    return this.http.post(`adminEntity/enterprise-subscription`, body);
  }

  updateEntityStatus(formValues: any) {
    return this.http.post(`adminEntity/update-entity-status`, formValues);    
  }

  uploadImage(image: any) {
    return this.http.post(`org/upload-image`, image, { responseType: 'text' });
  }

  updateAll(form: any) {
    return this.http.put(`adminEntity`, form);
  }

  exportCSV(params: any) {
    return this.http.get(`adminEntity/organization-grid/export`, {
      params: params,
    });
  }
}
