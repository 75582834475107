
<div *ngIf="useReportingDB" class="reporting-db-banner">
  Viewing Reporting Database.
</div>


<div
  *ngIf="!inProd"
  class="env-banner"
  [ngClass]="{ 'env-banner-dev': context === 'dev', 'env-banner-test': context === 'test' }"
>
  ADMIN - {{ context }}
</div>

<div *ngIf="showRefreshBanner" class="banner-message">
  <div>
    <h3 class="learner text-primary font-bold">New Version Available</h3>
    <p>To continue training, click Refresh to get the latest version of the app.</p>
  </div>
  <div>
    <button type="button" class="btn-primary-outlined medium" style="background-color: #e7f6f3" (click)="refreshApp()">
      Refresh
    </button>
  </div>
</div>
